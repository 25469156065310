import { Rule } from "./../types";

export default class VideoProgressRule implements Rule {
  private event: Event;

  constructor(event: Event) {
    this.event = event;
  }

  validate(value: number): boolean {
    const target = this.event.target as HTMLVideoElement;
    const { currentTime, duration, tagName } = target;
    const progress = (currentTime / duration) * 100;

    if (tagName !== "VIDEO" || !duration) {
      return false;
    }

    return progress >= value;
  }
}
