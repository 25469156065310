import VideoProgressRule from "./video_progress_rule";
import VerticalScrollProgress from "./vertical_scroll_progress_rule";
import { Rule } from "./../types";

export default function create(name: string, event: Event): Rule {
  return {
    verticalScrollProgress: new VerticalScrollProgress(event),
    videoProgress: new VideoProgressRule(event),
  }[name];
}
