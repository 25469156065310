import { CustomEvent as Event } from "../types";
import interact from "interactjs";

export default class SwipeEvent implements Event {
  addListener(target: string | HTMLElement): void {
    interact(target).draggable({
      listeners: {
        end: (event) => this.handleSwipe(event),
      },
    });
  }

  private handleSwipe(event: Interact.DragEvent): void {
    const { target, swipe } = event;

    if (!swipe) return;

    const { left, right, velocity } = swipe;
    const direction = left ? "left" : "right";

    if (left || right) {
      const swipedEvent = new CustomEvent("swipe", {
        bubbles: true,
        detail: { direction, velocity },
      });

      target.dispatchEvent(swipedEvent);
    }
  }
}
