import BaseAnalyticsController from "./base_controller";
import ConfigManager from "./utils/config_manager";
import EventListenerManager from "./utils/event_listener_manager";
import InteractionHandler from "./utils/interaction_handler";
import { createDebouncedFunction } from "./utils";
import { Config, InteractionConfig } from "./types";
import CustomEventManager from "./utils/custom_event_manager";
import createRule from "./rules";

const defaultConfig: Config = {
  prefix: null,
  debounce: true,
  once: false,
  interactions: [],
};

export default class extends BaseAnalyticsController {
  declare readonly configValue: Config;

  static values = {
    config: Object as () => Config,
  };

  private readonly debounceDuration = 300;
  private readonly eventManager = new CustomEventManager();
  private readonly eventListenerManager = new EventListenerManager();
  private readonly debouncedTrackEvent = createDebouncedFunction(
    (trackEvent: () => void) => trackEvent(),
    this.debounceDuration
  );

  private configManager: ConfigManager<Config>;
  private interactionHandler: InteractionHandler;

  get config(): Config {
    return this.configManager.mergedConfig;
  }

  constructor(context) {
    super(context);

    this.configManager = new ConfigManager(defaultConfig, this.configValue);
    const { prefix } = this.configManager.mergedConfig;
    this.interactionHandler = new InteractionHandler(
      prefix || "",
      this.trackEvent.bind(this)
    );
  }

  connect(): void {
    try {
      this.setupInteractions();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  private setupInteractions(): void {
    const { interactions } = this.config;

    this.eventManager.initializeEvents(interactions);

    interactions.forEach((interaction) => {
      const { once } = interaction;
      interaction.once = once ?? this.config.once;

      this.eventListenerManager.addEventListener(
        this.element,
        interaction,
        (event: Event) =>
          this.eventListenerManager.processEvent(
            interaction,
            event,
            this.trackInteraction.bind(this)
          )
      );
    });
  }

  private trackInteraction(event: Event, interaction: InteractionConfig): void {
    const { debounce } = this.config;
    const { name, rule: ruleConfig, once } = interaction;

    try {
      if (ruleConfig) {
        const { name: ruleName, value: ruleValue } = ruleConfig;
        if (ruleName) {
          const rule = createRule(ruleName, event);
          if (!rule || !rule.validate(ruleValue)) return;
        }
      }

      const trackEvent = () =>
        this.interactionHandler.handleInteraction(event, interaction);

      if (once)
        this.eventListenerManager.removeEventListeners(this.element, name);

      debounce ? this.debouncedTrackEvent(trackEvent) : trackEvent();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
