import { Extractor } from "./../types";

export default class LinkExtractor implements Extractor {
  extract(target: HTMLElement): Record<string, unknown> {
    const anchor = target.closest("a");
    if (anchor instanceof HTMLAnchorElement) {
      return {
        href: anchor.href,
        text: anchor.textContent?.trim(),
      };
    }

    return {};
  }
}
