import { EventMap } from "../custom_events";
import { InteractionConfig } from "../types";

export default class CustomEventManager {
  initializeEvents(interactions: InteractionConfig[]): void {
    interactions.forEach(({ type, selector }) => {
      const eventTypes = Array.isArray(type) ? type : [type];
      eventTypes.forEach((eventType) => {
        const listener = EventMap[eventType];
        if (listener) {
          listener.addListener(selector);
        }
      });
    });
  }
}
