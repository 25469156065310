import { Rule } from "./../types";

export default class VerticalScrollProgressRule implements Rule {
  private event: Event;

  constructor(event: Event) {
    this.event = event;
  }

  validate(value: number): boolean {
    const target = this.event.target as HTMLElement;
    const progress = this.calculateScrollProgress(target);

    return progress >= value;
  }

  private calculateScrollProgress(container: HTMLElement | Document): number {
    let scrollTop = 0;
    let scrollHeight = 0;
    let clientHeight = 0;

    if (
      container === document ||
      container === document.documentElement ||
      container === document.body
    ) {
      scrollTop = window.scrollY;
      scrollHeight = document.documentElement.scrollHeight;
      clientHeight = document.documentElement.clientHeight;
    } else if (container instanceof HTMLElement) {
      scrollTop = container.scrollTop;
      scrollHeight = container.scrollHeight;
      clientHeight = container.clientHeight;
    }

    return Math.min(1, scrollTop / (scrollHeight - clientHeight));
  }
}
