import { InteractionConfig } from "../types";

export default class EventListenerManager {
  private readonly eventListeners = new Map<
    string,
    Record<string, EventListener>
  >();

  addEventListener(
    element: HTMLElement | Element,
    interaction: { name: string; type: string | string[] },
    handler: (event: Event) => void
  ): void {
    const { name, type } = interaction;
    const eventTypes = Array.isArray(type) ? type : [type];
    const listeners: Record<string, EventListener> = {};

    eventTypes.forEach((eventType) => {
      const listener = handler;
      element.addEventListener(eventType, listener, {
        passive: true,
        capture: true,
      });
      listeners[eventType] = listener;
    });

    this.eventListeners.set(name, listeners);
  }

  removeEventListeners(element: HTMLElement | Element, name: string): void {
    const listeners = this.eventListeners.get(name);
    if (!listeners) return;

    Object.entries(listeners).forEach(([eventType, listener]) => {
      element.removeEventListener(eventType, listener, {
        capture: true,
      });
    });

    this.eventListeners.delete(name);
  }

  processEvent(
    interaction: InteractionConfig,
    event: Event,
    callback: (event: Event, interaction: InteractionConfig) => void
  ): void {
    const { selector } = interaction;
    const eventTarget = event.target as HTMLElement;
    const matchingElement = eventTarget.matches(selector)
      ? eventTarget
      : eventTarget.closest(selector);

    if (matchingElement) {
      callback(event, interaction);
    }
  }
}
