import { ExtractorMap } from "../extractors";

export default class InteractionHandler {
  constructor(
    private prefix: string,
    private trackEvent: (name: string, props: object) => void
  ) {}

  handleInteraction(
    event: Event,
    interaction: { extractor: string; name: string; type: string }
  ): void {
    const eventTarget = event.target as HTMLElement;
    const { extractor, name } = interaction;

    const extractorInstance = ExtractorMap[extractor];
    const extractedProperties = extractorInstance?.extract(eventTarget) || {};

    const properties = {
      interaction_type: event.type,
      ...extractedProperties,
    };

    this.trackEvent(`${this.prefix || ""} ${name}`, properties);
  }
}
